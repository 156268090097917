import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as indexilIah7Fvu5Meta } from "/opt/build/repo/pages/events/[id]/index.vue?macro=true";
import { default as rejoindreEp71zIyEnqMeta } from "/opt/build/repo/pages/events/[id]/rejoindre.vue?macro=true";
import { default as nouveautgzgV0WvW7Meta } from "/opt/build/repo/pages/events/nouveau.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "events-id___en",
    path: "/en/events/:id()",
    meta: indexilIah7Fvu5Meta || {},
    component: () => import("/opt/build/repo/pages/events/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "events-id___fr",
    path: "/fr/events/:id()",
    meta: indexilIah7Fvu5Meta || {},
    component: () => import("/opt/build/repo/pages/events/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "events-id-rejoindre___en",
    path: "/en/events/:id()/rejoindre",
    meta: rejoindreEp71zIyEnqMeta || {},
    component: () => import("/opt/build/repo/pages/events/[id]/rejoindre.vue").then(m => m.default || m)
  },
  {
    name: "events-id-rejoindre___fr",
    path: "/fr/events/:id()/rejoindre",
    meta: rejoindreEp71zIyEnqMeta || {},
    component: () => import("/opt/build/repo/pages/events/[id]/rejoindre.vue").then(m => m.default || m)
  },
  {
    name: "events-nouveau___en",
    path: "/en/events/nouveau",
    component: () => import("/opt/build/repo/pages/events/nouveau.vue").then(m => m.default || m)
  },
  {
    name: "events-nouveau___fr",
    path: "/fr/events/nouveau",
    component: () => import("/opt/build/repo/pages/events/nouveau.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  }
]