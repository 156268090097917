export default defineAppConfig({
  ui: {
    primary: "green",
    gray: "black",
    notifications: {
      position: "bottom-0 left-1/2 transform -translate-x-1/2",
    },
    formGroup: {
      label: {
        base: "font-medium text-black-800 font-xs",
      },
    },
    input: {
      rounded: "rounder-lg",
      placeholder: "placeholder-black-500",
      color: {
        koncord: {
          outline:
            "border-2 ring-2 ring-transparent focus:ring-black-950 focus:ring-2 border-none focus:shadow-custom4 error:ring-green-950",
        },
      },
      default: {
        size: "lg",
        color: "koncord",
        variant: "outline",
      },
    },
    textarea: {
      color: {
        koncord: {
          outline:
            "border-2 ring-2 ring-transparent focus:ring-black-950 focus:ring-2 border-none focus:shadow-custom4 error:ring-green-950",
        },
      },
      default: {
        size: "lg",
        color: "koncord",
        variant: "outline",
      },
    },
    button: {
      base: "text-center text-base  inline-flex items-center justify-center font-medium active:translate-x-0.5 active:translate-y-0.5 transition",
      padding: {
        lg: "px-10",
        md: "px-4",
      },
      size: {
        lg: "h-[45px]",
        md: "h-9",
      },

      rounded: "rounded-xl",
      color: {
        koncord: {
          solid:
            "bg-black-950 hover:bg-black-900  text-white  shadow-custom4 active:shadow-none ",
          outline:
            "bg-transparent hover:bg-black-50  text-black-950 border-2 border-black-950",
          soft: "bg-black-100 hover:bg-black-200 text-black-800",
          ghost: "bg-transparent hover:bg-black-50",
        },
      },
      default: {
        size: "lg",
        variant: "solid",
        color: "koncord",
        padding: "lg",
        loadingIcon: "i-material-symbols-sync",
      },
    },
    meterGroup: {
      list: "hidden",
    },
    popover: {
      overlay: {
        background: "bg-black-950/50 backdrop-blur-sm",
      },
    },
  },
});
